import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout";
import "animate.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Connect() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); //when we clicked on Discuss ideas button in MeWork page
  }, []);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    //stay on the same connect page after submission as it was redirecting to web3forms after submission
    name: "",
    email: "",
    number: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    // Check if e.target is defined and has a name property
    if (e.target) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else {
      // Handle PhoneInput directly
      setFormData({
        ...formData,
        number: e, // `e` is the value directly from PhoneInput
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    setIsSubmitting(true);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "0d4d43d2-3744-475c-956b-7c6b6ed73545",
          ...formData,
        }),
      });

      if (response.ok) {
        setIsSuccess(true);
        setFormData({
          name: "",
          email: "",
          number: "",
          address: "",
          message: "",
        });
      } else {
        console.error("Form submission error");
      }
    } catch (error) {
      console.error("Form submission error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout>
      <div className="pt-16 md:pt-20">
        <section className="flex flex-col mx-auto py-2 ">
          <div className="flex flex-col justify-center items-center bg-white animate__animated animate__fadeIn  space-y-2 sm:space-y-10">
            <div className="flex flex-col text-center max-w-4xl gap-12 pl-6 pr-6 pb-16 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24">
              <p className="text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
                Let's Connect
              </p>
              <div className="flex flex-col gap-4">
                <p className="text-xl md:text-2xl text-center leading-normal xs:leading-normal md:leading-normal">
                  Dreaming up a new venture? Let's connect and collaborate to
                  transform your concept into an extraordinary success that
                  stands out and makes an impact.
                </p>
                <p className="text-md md:text-lg text-slate-600 text-center leading-normal xs:leading-normal md:leading-normal">
                  "&lt;divs&gt;: Developer's Invisible Voodoo Spells"
                </p>
              </div>
            </div>
            <div className="bg-gray-50 w-full pl-8 pr-8 pt-20 pb-20 slg:p-20">
              <div className="max-w-2xl mx-auto bg-white p-6 border rounded-2xl animate__animated animate__headShake">
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col -m-2 gap-2"
                >
                  <input
                    type="hidden"
                    name="access_key"
                    value="0d4d43d2-3744-475c-956b-7c6b6ed73545"
                  />
                  <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
                    <div className="p-2 w-full">
                      <div className="relative">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="w-full bg-white border rounded-md text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                          placeholder="Name*"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="p-2 w-full">
                      <div className="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="w-full bg-white border rounded-md  focus:border-black text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                          placeholder="Email*"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-center gap-2">
                    <div className="p-2 w-full sm:w-1/2">
                      <div className="relative">
                        <PhoneInput
                          international
                          defaultCountry="IN"
                          id="number"
                          name="number"
                          className="w-full bg-white rounded-md border  focus:border-black text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                          placeholder="Phone number*"
                          value={formData.number}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="p-2 w-full sm:w-1/2">
                      <div className="relative">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className="w-full bg-white border rounded-md  focus:border-black text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                          placeholder="Enter your address"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-2 w-full">
                    <div className="relative">
                      <textarea
                        id="text"
                        name="message"
                        className="w-full bg-gray-50 rounded-md  focus:border-black h-32 text-base outline-none text-gray-700 py-2 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                        placeholder="Your message here*"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="flex items-center justify-center p-2 w-full">
                    <button
                      type="submit"
                      className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl w-full transition duration-300 ease-in-out"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Sending..." : "Let's Begin"}
                    </button>
                  </div>
                </form>
                {isSuccess && (
                  <div className="p-4 mt-4 text-green-600 border border-green-300 rounded-md bg-green-50">
                    Message sent successfully!
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Connect;
