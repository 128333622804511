import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CustomCursor from "../components/CustomCursor";
import RotatingDiv from "../components/RotatingDiv";
function Layout({ children }) {
  return (
    <div className="flex flex-col">
      <CustomCursor />
      <Navbar></Navbar>
      <div>{children}</div>
      <RotatingDiv></RotatingDiv>
      <Footer></Footer>
    </div>
  );
}

export default Layout;
