// Clock.jsx
import React, { useState, useEffect } from "react";

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const formatTime = (date) => {
    let hour = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();
    let am_pm = "AM";

    // Setting time for 12 Hrs format
    if (hour >= 12) {
      if (hour > 12) hour -= 12;
      am_pm = "PM";
    } else if (hour === 0) {
      hour = 12;
      am_pm = "AM";
    }

    hour = hour < 10 ? "0" + hour : hour;
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;

    return `${hour}:${min}:${sec} ${am_pm}`;
  };

  return (
    <div className="flex flex-col text-lg w-[150px] mx-auto text-center pt-2 pb-2">
      <div className="text-gray-400 text-sm font-normal">Bharat, India</div>
      <div className="text-md">{formatTime(time)}</div>
    </div>
  );
};

export default Clock;
