//rotating music component
import React, { useEffect, useRef, useState } from "react";
import rotating_music from "../assets/rotating_music.png";
import { useAudio } from "./AudioProvider"; //import useAudio from AudioProvider

function RotatingDiv() {
  const { toggleAudio } = useAudio(); //useAudio is a custom hook created in AudioProvider. When called here, it returns the values provided by AudioContext in AudioProvider, specifically the toggleAudio function
  const textRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.style.transform = `rotate(${scrollY / 10}deg)`;
    }
  }, [scrollY]);

  return (
    <div className="fixed bottom-2 right-2">
      <div className="relative w-10 h-10 sm:w-12 sm:h-12 bg-gray-100 hover:border-2 hover:border-black-500 rounded-full flex justify-center items-center">
        <div
          className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-gray-800 font-bold"
          ref={textRef}
        >
          <img
            src={rotating_music}
            alt=""
            className="w-6 h-6 sm:w-8 sm:h-8 object-contain"
            onClick={toggleAudio} //user clicks on the icon, toggleAudio is called, toggling the audio between play and pause states. Also as toggleAudio is handled by AudioProvider this way, the audio’s state remains consistent across page transitions and audio does not stop on moving to some other page.
          />
        </div>
      </div>
    </div>
  );
}

export default RotatingDiv;
