import React from "react";
import { Link, useLocation } from "react-router-dom";

function Footer() {
  const { pathname } = useLocation();

  const handleClick = (path) => {
    if (pathname === path) {
      // If we're already on the target page, handle hash scrolling and move to that section of the page
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      // when not on the target page and click on footer click then move to that page and scroll to top for other pages
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return (
    <footer className="bg-white text-black p-4 pt-20 px-4 sm:px-6 md:px-8 lg:px-12">
      <div className="mx-auto flex flex-col items-center gap-8">
        <div className="flex flex-col justify-center items-center gap-8 w-full max-w-full">
          <Link to="/connect" className="mb-4 sm:mb-0">
            <button
              onClick={handleClick}
              className="bg-black hover:bg-white hover:text-black text-white font-semibold text-md py-2 px-4 border border-gray-700 rounded-xl transition duration-300 ease-in-out"
            >
              Let’s Connect
            </button>
          </Link>
          <div className="flex flex-wrap gap-4 justify-center">
            <Link to="/" className="text-lg sm:text-xl font-medium">
              <p onClick={handleClick} className="link_hover_effect">
                me&work
              </p>
            </Link>
            <Link
              to="/#publications"
              className="text-lg sm:text-xl font-medium"
            >
              <p onClick={() => handleClick("/")} className="link_hover_effect">
                Publications
              </p>
            </Link>
            <Link to="/gigging" className="text-lg sm:text-xl font-medium">
              <p onClick={handleClick} className="link_hover_effect">
                Gigging
              </p>
            </Link>
            <Link to="/models" className="text-lg sm:text-xl font-medium">
              <p onClick={handleClick} className="link_hover_effect">
                Models
              </p>
            </Link>
            <Link to="/canvas" className="text-lg sm:text-xl font-medium">
              <p onClick={handleClick} className="link_hover_effect">
                Canvas
              </p>
            </Link>
            <Link to="/me_life" className="text-lg sm:text-xl font-medium">
              <p onClick={handleClick} className="link_hover_effect">
                Highlights
              </p>
            </Link>
            <Link to="/devcentral" className="text-lg sm:text-xl font-medium">
              <p onClick={handleClick} className="link_hover_effect">
                DevCentral
              </p>
            </Link>
          </div>
        </div>
        <p className="text-center text-gray-400 text-sm">
          Copyright © {new Date().getFullYear()} Prabhav Dev
        </p>
      </div>
    </footer>
  );
}

export default Footer;
