import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import "animate.css";
import useInView from "../../components/useInView"; // Import the custom hook
import college_achievement from "../../assets/college_achievement.jpg";
import table_tennis_achievement from "../../assets/table_tennis_achievement.jpg";
import environment_achievement from "../../assets/environment_achievement.jpg";

function MeLife() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  return (
    <Layout>
      <div class="pt-16 md:pt-20 bg-white">
        <div class="flex flex-col space-y-2 sm:space-y-10 mx-auto py-2 ">
          <div className="flex justify-center items-center pl-6 pr-6 pb-16 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 bg-white animate__animated animate__fadeIn">
            <div className="grid grid-cols-1 max-w-4xl items-stretch mx-auto gap-12">
              <p className="text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
                me&life
              </p>
              <div className="flex flex-col justify-center items-center gap-8">
                <p className="text-xl md:text-2xl text-center leading-normal xs:leading-normal md:leading-normal">
                  Welcome to My Life’s Gallery, where every achievement,
                  passion, and adventure is a highlight on the frame of my
                  experiences.
                </p>
                <a
                  href="https://www.instagram.com/prabhavdevgupta/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="hover:bg-black bg-white text-black hover:text-white font-normal text-md py-3 px-3 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                    Let's Get Social on Instagram
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center pb-24 pt-24 pl-12 pr-12 sm:pl-20 sm:pr-20 lg:pl-24 lg:pr-24 bg-gray-50">
            <div className="grid grid-cols-1 gap-20 items-stretch max-w-6xl mx-auto">
              <div className="text-3xl">Achievements</div>
              <div className="grid grid-col-1 md:grid-cols-2 xl:grid-cols-3 gap-12 rounded-xl">
                <div
                  className="flex flex-col space-y-4 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={ref1}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="/table_tennis_achievement.pdf"
                        download="table_tennis_achievement.pdf"
                        className="hover:underline text-md slg:text-lg font-semibold"
                      >
                        National Table Tennis Player
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInView1 ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-sm">
                      I am proud to hold the title of Himachal Number-1 for an
                      impressive six consecutive times. Representing Himachal
                      Pradesh in various states across India has been a
                      privilege and a testament to my dedication to the sport.
                    </p>
                  </div>

                  <div
                    className={`flex flex-col justify-center gap-6 ${
                      isInView1 ? "animate-fadeInFormView" : ""
                    }`}
                  >
                    <a
                      href="/table_tennis_achievement.pdf"
                      download="table_tennis_achievement.pdf"
                    >
                      <img
                        src={table_tennis_achievement}
                        alt="table tennis achievement"
                        className="w-full h-auto rounded-xl border border-gray-200"
                      />
                    </a>
                  </div>
                </div>
                <div
                  className="flex flex-col space-y-4 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={ref2}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="/college_achievement.pdf"
                        download="college_achievement.pdf"
                        className="hover:underline text-md slg:text-lg font-semibold"
                      >
                        Best Performer
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInView2 ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-sm">
                      Honored to receive an award from UPES in recognition of my
                      outstanding performance in extracurricular activities. I
                      am grateful for the opportunity to contribute to the
                      vibrant extracurricular community at UPES.
                    </p>
                  </div>

                  <div
                    className={`flex flex-col justify-center gap-6 ${
                      isInView2 ? "animate-fadeInFormView" : ""
                    }`}
                  >
                    <a
                      href="/college_achievement.pdf"
                      download="college_achievement.pdf"
                    >
                      <img
                        src={college_achievement}
                        alt="college achievement"
                        className="w-full h-auto rounded-xl border border-gray-200"
                      />
                    </a>
                  </div>
                </div>
                <div
                  className="flex flex-col space-y-4 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={ref3}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="/environment_achievement.pdf"
                        download="environment_achievement.pdf"
                        className="hover:underline text-md slg:text-lg font-semibold"
                      >
                        Environment Social Activist
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInView3 ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-sm">
                      As an active member of the Environment Society of
                      Nahan(registered), I'm proud to receive a certificate for
                      my efforts in promoting clean communities and educating
                      others about environmental pollution.
                    </p>
                  </div>

                  <div
                    className={`flex flex-col justify-center gap-6 ${
                      isInView3 ? "animate-fadeInFormView" : ""
                    }`}
                  >
                    <a
                      href="/environment_achievement.pdf"
                      download="environment_achievement.pdf"
                    >
                      <img
                        src={environment_achievement}
                        alt="environment achievement"
                        className="w-full h-auto rounded-xl border border-gray-200"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MeLife;
