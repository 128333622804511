import React, { useEffect, useState } from "react";
import AnimatedCursor from "react-animated-cursor";

function CustomCursor() {
  const [isTouchDevice, setIsTouchDevice] = useState(false); //cursor animation is not applied when it is a touch device

  useEffect(() => {
    // Detect if the user is on a touch device
    const checkTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };

    checkTouchDevice();
    window.addEventListener("resize", checkTouchDevice); // Handle resize or rotation

    return () => window.removeEventListener("resize", checkTouchDevice);
  }, []);

  // Render the cursor only if it's not a touch device
  return (
    <div>
      {!isTouchDevice && (
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={1.7}
          outerAlpha={0}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: "#000000",
          }}
          outerStyle={{
            border: "3px solid black",
          }}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      )}
    </div>
  );
}

export default CustomCursor;
