//for animations applied when element is already in view and when we scroll, not using react-animate-scroll as when element was already in view animations are not applied

import { useState, useEffect } from "react";

const useInView = (ref) => {
  //useInView is a custom react hook as function that starts with the prefix use and follows the rules of hooks is considered a custom hook.
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { top, bottom } = ref.current.getBoundingClientRect();
        const isVisible = top < window.innerHeight && bottom >= 0;
        setIsInView(isVisible);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [ref]);

  return isInView;
};

export default useInView;
