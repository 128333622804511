import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import "animate.css";
import useInView from "../../components/useInView"; // Import the custom hook
import omsidh_canvas1 from "../../assets/omsidh_canvas1.png";
import bitla_omsidh1 from "../../assets/bitla_omsidh1.png";
import bitla_omsidh2 from "../../assets/bitla_omsidh2.png";
import bitla_omsidh3 from "../../assets/bitla_omsidh3.png";
import bitla_omsidh4 from "../../assets/bitla_omsidh4.png";
import pa_image1 from "../../assets/pa_image1.png";
import pa_image2 from "../../assets/pa_image2.png";
import pa_image3 from "../../assets/pa_image3.png";
import pa_image4 from "../../assets/pa_image4.png";
import LOR_PA from "../../assets/LOR_PA.pdf";

function Gigging() {
  const refOm = useRef(null);
  const refPerfect = useRef(null);

  const isInViewOm = useInView(refOm);
  const isInViewPerfect = useInView(refPerfect);
  return (
    <Layout>
      <div className="pt-16 md:pt-20 bg-white">
        <div className="flex flex-col space-y-2 sm:space-y-10 mx-auto py-2 ">
          <div className="flex justify-center items-center pl-6 pr-6 pb-16 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 bg-white animate__animated animate__fadeIn">
            <div className="grid grid-cols-1 max-w-4xl items-stretch mx-auto gap-12">
              <p className="text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
                Gigging
              </p>

              <div className="flex flex-col justify-center items-center gap-4">
                <p className="text-xl md:text-2xl text-center leading-normal xs:leading-normal md:leading-normal">
                  Consulting Excellence: From concept to execution, my
                  consulting and freelance portfolio showcases a track record of
                  client partnerships that drive growth, efficiency, and
                  impactful results.
                </p>
                <p className="text-md md:text-lg text-slate-600 text-center leading-normal xs:leading-normal md:leading-normal">
                  {" "}
                  Creating actionable plans that yield significant results is my
                  forte.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pb-24 pt-24 pl-8 pr-8 xs:pl-12 xs:pr-12 sm:pl-16 sm:pr-16 lg:pl-20 lg:pr-20 bg-gray-50">
            <div className="grid grid-cols-1 items-stretch max-w-6xl mx-auto pt-4">
              <div className="grid grid-cols-1 slg:grid-cols-2 gap-16 xl:gap-24 rounded-xl">
                {/* OM SIDH TOUR AND TRAVELS Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md "
                  ref={refOm}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="https://omsidhexpress.in/"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-lg lg:text-xl font-semibold"
                      >
                        OM SIDH TOUR AND TRAVELS
                      </a>
                    </div>
                    <p className="text-gray-500 text-xs">
                      May 2024 - October 2024
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewOm ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Managed various aspects of Volvo bus operations, including
                      booking scheduling, client management, and fare updates.
                      Additionally, I developed comprehensive bus charts,
                      oversaw seat management, and managed finances to ensure
                      seamless operations.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewOm ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Constructed a full-featured Volvo bus ticket booking
                      website using WordPress, allowing customers to book
                      tickets and pay directly into the operator's bank account.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewOm ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}
                      <a
                        href="https://ostt.omsidhexpress.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={bitla_omsidh1}
                          alt="OM SIDH TOUR AND TRAVELS 1"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://ostt.omsidhexpress.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={bitla_omsidh2}
                          alt="OM SIDH TOUR AND TRAVELS 2"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://ostt.omsidhexpress.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={bitla_omsidh3}
                          alt="OM SIDH TOUR AND TRAVELS 3"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://ostt.omsidhexpress.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={bitla_omsidh4}
                          alt="OM SIDH TOUR AND TRAVELS 3"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://omsidhexpress.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={omsidh_canvas1}
                          alt="OM SIDH TOUR AND TRAVELS 3"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="https://omsidhexpress.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Visit Now
                        </button>
                      </a>
                    </div>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    WordPress, WooCommerce, Plugins, PHP, HTML/CSS, JavaScript,
                    Bitla Software, Scheduling
                  </p>
                </div>

                {/* Perfect Advertisers Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={refPerfect}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-black">
                      <p className="text-lg lg:text-xl font-semibold">
                        Perfect Advertisers
                      </p>
                    </div>
                    <p className="text-gray-500 text-xs">
                      April 2023 - May 2023
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewPerfect ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Streamlined the website development process using agile
                      methodologies for quicker project completion and improved
                      client satisfaction, while also showcasing expertise in
                      elevation, signage, and branding to highlight a commitment
                      to design excellence.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewPerfect ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Employed the SDLC model to build the website, conducted
                      thorough platform checks, and developed a prototype for
                      client approval. Submitted the project in manageable
                      fragments to facilitate client feedback and ensure
                      alignment with their expectations.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewPerfect ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}

                      <img
                        src={pa_image1}
                        alt="Perfect Advertisers"
                        className="w-full h-50 object-contain"
                      />

                      <img
                        src={pa_image2}
                        alt="Perfect Advertisers"
                        className="w-full h-50 object-contain"
                      />

                      <img
                        src={pa_image3}
                        alt="Perfect Advertisers"
                        className="w-full h-50 object-contain"
                      />
                      <img
                        src={pa_image4}
                        alt="Perfect Advertisers"
                        className="w-full h-50 object-contain"
                      />
                    </div>
                  </div>
                  <div>
                    <a
                      href={LOR_PA}
                      download="LOR_PA.pdf"
                      className="hover:underline text-md lg:text-lg font-semibold text-blue-700"
                    >
                      Letter of Recommendation
                    </a>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    HTML, CSS, JavaScript, React.js, Design, Sketch, SDLC
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Gigging;
