import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import "animate.css";
import useInView from "../../components/useInView"; // Import the custom hook
import pa_image1 from "../../assets/pa_image1.png";
import pa_image3 from "../../assets/pa_image3.png";
import growfin_canvas1 from "../../assets/growfin_canvas1.png";
import growfin_canvas2 from "../../assets/growfin_canvas2.png";
import omsidh_canvas3 from "../../assets/omsidh_canvas3.png";
import omsidh_canvas2 from "../../assets/omsidh_canvas2.png";
import exalt_canvas3 from "../../assets/exalt_canvas3.jpeg";
import portal_canvas3 from "../../assets/portal_canvas3.png";
import portal_image1 from "../../assets/portal_image1.png";
import exalt_image1 from "../../assets/exalt_image1.png";

function Canvas() {
  //usage defined in useInView.jsx
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);
  const isInView4 = useInView(ref4);
  const isInView5 = useInView(ref5);
  const isInView6 = useInView(ref6);

  return (
    <Layout>
      <div className="pt-16 md:pt-20 bg-white">
        <div className="space-y-2 sm:space-y-10 px-2 mx-auto py-2 lg:px-6">
          <div className="flex justify-center items-center pl-6 pr-6 pb-16 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 bg-white animate__animated animate__fadeIn">
            <div className="grid grid-cols-1 max-w-4xl items-stretch mx-auto gap-12">
              <p className="text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
                Canvas
              </p>
              <div className="flex flex-col justify-center items-center gap-4">
                <p className="text-xl md:text-2xl text-center leading-normal xs:leading-normal md:leading-normal">
                  Dive into a realm where creativity meets technology. My work
                  is crafted to elevate web experiences into unforgettable
                  journeys. Every creation reflects the power of thoughtful
                  development.
                </p>
                <p className="text-md md:text-lg text-slate-600 text-center leading-normal xs:leading-normal md:leading-normal">
                  {" "}
                  Elevate your ideas and craft outstanding software solutions
                  together.
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full py-4 px-4 mx-auto max-w-screen-xl sm:py-2 lg:px-6">
            <div
              className="col-span-2 sm:col-span-1 md:col-span-2 bg-white h-auto md:h-full flex flex-col"
              ref={ref1}
            >
              <div
                className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow ${
                  isInView1 ? "animate-fadeInFormView" : ""
                }`}
              >
                <img
                  src={pa_image1}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
              </div>
            </div>

            <div
              className="col-span-2 sm:col-span-1 md:col-span-2 bg-white"
              ref={ref2}
            >
              <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 mb-4">
                <a
                  href="https://growfin.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                  className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 ${
                    isInView2 ? "animate-fadeInFormView" : ""
                  }`}
                >
                  <img
                    src={growfin_canvas1}
                    alt=""
                    className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
                </a>
                <a
                  href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                  target="_blank"
                  rel="noreferrer"
                  className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 ${
                    isInView2 ? "animate-fadeInFormView" : ""
                  }`}
                >
                  <img
                    src={portal_image1}
                    alt=""
                    className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
                </a>
              </div>
              <a
                href="https://growfin.vercel.app/"
                target="_blank"
                rel="noreferrer"
                className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 ${
                  isInView2 ? "animate-fadeInFormView" : ""
                }`}
              >
                <img
                  src={growfin_canvas2}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
              </a>
            </div>
            <div
              className="col-span-2 sm:col-span-1 md:col-span-1 bg-white h-auto md:h-full flex flex-col"
              ref={ref3}
            >
              <a
                href="https://omsidhexpress.in/"
                target="_blank"
                rel="noreferrer"
                className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow ${
                  isInView3 ? "animate-fadeInFormView" : ""
                }`}
              >
                <img
                  src={omsidh_canvas3}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
              </a>
            </div>
            <div
              className="col-span-2 sm:col-span-1 md:col-span-2 bg-white"
              ref={ref4}
            >
              <a
                href="https://omsidhexpress.in/"
                target="_blank"
                rel="noreferrer"
                className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4 ${
                  isInView4 ? "animate-fadeInFormView" : ""
                }`}
              >
                <img
                  src={omsidh_canvas2}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
              </a>
              <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                <div
                  className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40  ${
                    isInView4 ? "animate-fadeInFormView" : ""
                  }`}
                >
                  <img
                    src={pa_image3}
                    alt=""
                    className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
                </div>
                <a
                  href="https://exalt-fitness.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                  className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40  ${
                    isInView4 ? "animate-fadeInFormView" : ""
                  }`}
                >
                  <img
                    src={exalt_canvas3}
                    alt=""
                    className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
                </a>
              </div>
            </div>
            <div
              className="col-span-2 sm:col-span-1 md:col-span-1 bg-white h-auto md:h-full flex flex-col"
              ref={ref5}
            >
              <a
                href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                target="_blank"
                rel="noreferrer"
                className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow ${
                  isInView5 ? "animate-fadeInFormView" : ""
                }`}
              >
                <img
                  src={portal_canvas3}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
              </a>
            </div>
            <div
              className="col-span-2 sm:col-span-1 md:col-span-2 bg-white h-auto md:h-full flex flex-col"
              ref={ref6}
            >
              <a
                href="https://exalt-fitness.vercel.app/"
                target="_blank"
                rel="noreferrer"
                className={`group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow ${
                  isInView6 ? "animate-fadeInFormView" : ""
                }`}
              >
                <img
                  src={exalt_image1}
                  alt=""
                  className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-gray-900/5 to-gray-900/5"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Canvas;
