import React, { useRef } from "react";
import Layout from "../../layout/Layout";
import "animate.css";
import useInView from "../../components/useInView"; // Import the custom hook
import bookingo_image1 from "../../assets/bookingo_image1.jpeg";
import bookingo_image2 from "../../assets/bookingo_image2.png";
import exalt_image1 from "../../assets/exalt_image1.png";
import exalt_image2 from "../../assets/exalt_image2.png";
import exalt_image3 from "../../assets/exalt_image3.png";
import exalt_image4 from "../../assets/exalt_image4.png";
import growfin_canvas1 from "../../assets/growfin_canvas1.png";
import growfin_canvas2 from "../../assets/growfin_canvas2.png";
import growfin_image4 from "../../assets/growfin_image4.png";
import growfin_image5 from "../../assets/growfin_image5.png";
import growfin_image6 from "../../assets/growfin_image6.png";
import portal_image1 from "../../assets/portal_image1.png";
import portal_image2 from "../../assets/portal_image2.jpeg";
import portal_image4 from "../../assets/portal_image4.png";
import portfolio_image1 from "../../assets/portfolio_image1.png";
import portfolio_image2 from "../../assets/portfolio_image2.png";
import portfolio_image3 from "../../assets/portfolio_image3.png";
import portfolio_image4 from "../../assets/portfolio_image4.png";

function Models() {
  const refBookingo = useRef(null);
  const refExalt = useRef(null);
  const refGrowfin = useRef(null);
  const refPortal = useRef(null);
  const refPortfolio = useRef(null);

  const isInViewBookingo = useInView(refBookingo);
  const isInViewExalt = useInView(refExalt);
  const isInViewGrowfin = useInView(refGrowfin);
  const isInViewPortal = useInView(refPortal);
  const isInViewPortfolio = useInView(refPortfolio);
  return (
    <Layout>
      <div className="pt-16 md:pt-20 bg-white">
        <div className="flex flex-col space-y-2 sm:space-y-10 mx-auto py-2 ">
          <div className="flex justify-center items-center pl-6 pr-6 pb-16 md:pl-12 md:pr-12 lg:pl-24 lg:pr-24 bg-white animate__animated animate__fadeIn">
            <div className="grid grid-cols-1 max-w-4xl items-stretch mx-auto gap-12">
              <p className="text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
                Models
              </p>

              <div className="flex flex-col justify-center items-center gap-4">
                <p className="text-xl md:text-2xl text-center leading-normal xs:leading-normal md:leading-normal">
                  Journey of Skill Enhancement: Showcasing Projects That
                  Highlight My Dedication to Frontend Excellence, Emphasizing
                  Responsive Design, Performance Optimization, and Advanced
                  Functionality.
                </p>
                <p className="text-md md:text-lg text-slate-600 text-center leading-normal xs:leading-normal md:leading-normal">
                  {" "}
                  Let’s collaborate to bring your vision to life and create
                  something truly exceptional.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center pb-24 pt-24 pl-8 pr-8 xs:pl-12 xs:pr-12 sm:pl-16 sm:pr-16 lg:pl-20 lg:pr-20 bg-gray-50">
            <div className="grid grid-cols-1 items-stretch max-w-6xl mx-auto pt-4">
              <div className="grid grid-cols-1 slg:grid-cols-2 gap-16 xl:gap-24 rounded-xl">
                {/* Bookingo Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={refBookingo}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="https://github.com/Prabhav25/bookingo"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-xl font-semibold"
                      >
                        Bookingo
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewBookingo ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Developed a real estate platform with user authentication,
                      ad posting, Google API integration for property listings,
                      map-based location markers and search filters.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewBookingo ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Used Shadcn, Hyperui, Supabase for backend, Google Places
                      Autocomplete, Vercel for deployment and Clerk for
                      authentication and User Management.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewBookingo ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}
                      <a
                        href="https://bookingo-jade.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={bookingo_image1}
                          alt="Bookingo"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://bookingo-jade.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={bookingo_image2}
                          alt="Bookingo"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="https://github.com/Prabhav25/bookingo"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Git Repo
                        </button>
                      </a>
                    </div>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    Next.js, Tailwind CSS, Supabase, Formik, HTML/CSS, Shadcn,
                    Hyperui, Google API, Git, Vercel
                  </p>
                </div>

                {/* Growfin Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={refGrowfin}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-xl font-semibold"
                      >
                        Growfin
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewGrowfin ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Developed replica of Growfin.ai using React.js for a
                      dynamic and interactive user experience. The website is
                      designed to be aesthetically pleasing, making a strong
                      initial impression.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewGrowfin ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Responsive design, CSS transitions and transforms, and
                      advanced layout properties like Flexbox and CSS Grid
                      ensure a visually appealing and flexible website across
                      all devices.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewGrowfin ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={growfin_canvas1}
                          alt="Growfin"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={growfin_canvas2}
                          alt="Growfin"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={growfin_image4}
                          alt="Growfin"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={growfin_image5}
                          alt="Growfin"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={growfin_image6}
                          alt="Growfin"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                    </div>

                    <div className="flex items-center gap-4">
                      <a
                        href="https://github.com/Prabhav25/growfin"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Git Repo
                        </button>
                      </a>
                      <a
                        href="https://growfin.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Visit Now
                        </button>
                      </a>
                    </div>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    HTML/CSS, React.js, Responsiveness, Design architecture,
                    Sketch, Git, Vercel
                  </p>
                </div>

                {/* Web Portal Mechanism Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={refPortal}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-xl font-semibold"
                      >
                        Web Portal Mechanism
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewPortal ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Developed a web portal for my university which showcases
                      multiple achievements and provides you the information
                      about our university.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewPortal ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      This web portal solves the problems of students to study
                      from their preferred faculty and manage their timetable
                      according to their comfort-ability and choose the faculty
                      they prefer.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewPortal ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}
                      <a
                        href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portal_image1}
                          alt="Portal"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portal_image2}
                          alt="Portal"
                          className="w-full h-50 object-contain"
                        />
                      </a>

                      <a
                        href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portal_image4}
                          alt="Portal"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                    </div>

                    <div className="flex items-center gap-4">
                      <a
                        href="https://github.com/Prabhav25/Prabhav25.github.io-CollegeWebPortal"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Git Repo
                        </button>
                      </a>
                      <a
                        href="https://prabhav25.github.io/Prabhav25.github.io-CollegeWebPortal/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Visit Now
                        </button>
                      </a>
                    </div>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    HTML/CSS, Swiper JS, Responsiveness, Design architecture,
                    Sketch, Git
                  </p>
                </div>

                {/* Exalt Fitness Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={refExalt}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="https://exalt-fitness.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-xl font-semibold"
                      >
                        Exalt Fitness
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewExalt ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Deployed a fully functional and responsive fitness
                      theme-based website for Exalt Aesthetics, designed to
                      educate visitors on fitness and skin aesthetics.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewExalt ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      The aim is to provide personalized training programs,
                      connecting users with top athletes for expert guidance and
                      practical strategies for life transformation.
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewExalt ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}
                      <a
                        href="https://exalt-fitness.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={exalt_image1}
                          alt="Exalt Fitness"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://exalt-fitness.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={exalt_image2}
                          alt="Exalt Fitness"
                          className="w-full h-50 object-contain"
                        />
                      </a>

                      <a
                        href="https://exalt-fitness.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={exalt_image3}
                          alt="Exalt Fitness"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://exalt-fitness.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={exalt_image4}
                          alt="Exalt Fitness"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                    </div>

                    <div className="flex items-center gap-4">
                      <a
                        href="https://github.com/Prabhav25/exalt-fitness"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Git Repo
                        </button>
                      </a>
                      <a
                        href="https://exalt-fitness.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Visit Now
                        </button>
                      </a>
                    </div>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    Design, Sketch, HTML/CSS, JavaScript, React.js, Smtp.js,
                    Git, Vercel
                  </p>
                </div>

                {/* Personal Portfolio Section */}
                <div
                  className="flex flex-col space-y-6 p-8 rounded-2xl bg-white hover:shadow-md"
                  ref={refPortfolio}
                >
                  <div className="flex flex-col space-y-1">
                    <div className="flex items-center space-x-2 text-blue-700">
                      <a
                        href="https://personal-portfolio-five-beta-83.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline text-xl font-semibold"
                      >
                        Personal portfolio
                      </a>
                    </div>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewPortfolio ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      Designed and developed an attractive and user-friendly
                      portfolio interface using raw HTML, CSS, and JavaScript.
                      This resource is perfect for anyone looking to learn these
                      languages and create their own personal portfolio.
                    </p>
                  </div>

                  <div
                    className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                      isInViewPortfolio ? "animate-fadeInUpShort" : ""
                    }`}
                  >
                    <p className="text-gray-700 text-md">
                      The portfolio features six distinct sections and utilizes
                      various CSS properties along with the AOS library to add
                      engaging animation effects on scroll. Simply clone it from
                      GitHub, update the details in the code, and you're all set
                      to showcase your work!
                    </p>
                  </div>

                  <div className="flex flex-col justify-center gap-6">
                    <div
                      className={`w-full h-48 overflow-y-scroll hide-scrollbar rounded-xl border border-gray-200 ${
                        isInViewPortfolio ? "animate-fadeInFormView" : ""
                      }`}
                    >
                      {/* Image gallery container */}
                      <a
                        href="https://personal-portfolio-five-beta-83.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portfolio_image1}
                          alt="Portfolio"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://personal-portfolio-five-beta-83.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portfolio_image2}
                          alt="Portfolio"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://personal-portfolio-five-beta-83.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portfolio_image3}
                          alt="Portfolio"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                      <a
                        href="https://personal-portfolio-five-beta-83.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={portfolio_image4}
                          alt="Portfolio"
                          className="w-full h-50 object-contain"
                        />
                      </a>
                    </div>

                    <div className="flex items-center gap-4">
                      <a
                        href="https://github.com/Prabhav25/personal-portfolio/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Git Repo
                        </button>
                      </a>
                      <a
                        href="https://personal-portfolio-five-beta-83.vercel.app/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                          Visit Now
                        </button>
                      </a>
                    </div>
                  </div>

                  <p className="text-gray-600 text-md">
                    <span className="font-semibold text-gray-800">
                      Tech stack used:{" "}
                    </span>
                    HTML/CSS, Responsiveness, Design architecture, JavaScript,
                    Git, Vercel
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Models;
