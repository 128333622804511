import { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import navbar_logo from "../assets/navbar_logo.png";
import gmail from "../assets/gmail.png";
import linkedin from "../assets/linkedin.png";
import github from "../assets/github.png";
import cv from "../assets/cv.png";
import { Link, useLocation } from "react-router-dom";
import "animate.css";
import Clock from "./Clock";

const navigation = [
  { name: "me&work", to: "/", current: true },
  { name: "Gigging", to: "/gigging", current: false },
  { name: "Models", to: "/models", current: false },
  { name: "Canvas", to: "/canvas", current: false },
  { name: "Connect", to: "/connect", current: false },
  { name: "DevCentral", to: "/devcentral", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    let lastScrollTop = window.scrollY;

    const handleScroll = () => {
      const currentScrollTop = window.scrollY;
      if (currentScrollTop > lastScrollTop) {
        // Scroll down
        setVisible(false);
      } else {
        // Scroll up
        setVisible(true);
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { pathname } = useLocation();

  const handleClick = (path) => {
    if (pathname === path) {
      // If we're already on the target page, handle hash scrolling and move to that section of the page
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      // when not on the target page and click on footer click then move to that page and scroll to top for other pages
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Disclosure
      as="nav"
      className={`bg-white animate_animated animate_fadeIn z-20 sticky top-0 left-0 right-0 transition-transform duration-300 ${
        visible ? "translate-y-0" : "-translate-y-full "
      }`}
    >
      <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8  ">
        <div className="relative flex h-16 items-center justify-center">
          <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-black hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block h-6 w-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden h-6 w-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 gap-0 lg:gap-8 xl:gap-14 items-center justify-start lg:justify-center">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/">
                <img
                  alt="Prabhav dev"
                  src={navbar_logo}
                  className="h-16 w-auto mr-4 xs:h-20"
                />
              </Link>
            </div>

            <div className="w-full lg:w-auto flex items-center justify-end gap-8 lg:gap-2 xl:gap-6 sm:justify-center mr-16 lg:mx-8 xl:mx-0">
              <div className="flex flex-shrink-0 items-center justify-center gap-3">
                <a
                  href="mailto: prabhavdevgupta@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="gmail"
                    src={gmail}
                    className="h-7 w-auto transition-transform duration-300 ease-in-out hover:translate-y-[-4px]"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/prabhav-dev-00b878251/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="linkedin"
                    src={linkedin}
                    className="h-7 w-auto  transition-transform duration-300 ease-in-out hover:translate-y-[-4px]"
                  />
                </a>
                <a
                  href="https://github.com/Prabhav25/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    alt="github"
                    src={github}
                    className="h-7 w-auto  transition-transform duration-300 ease-in-out hover:translate-y-[-4px]"
                  />
                </a>
                <a href="/resume.pdf" download="resume.pdf">
                  <img
                    alt="cv"
                    src={cv}
                    className="h-8 w-auto transition-transform duration-300 ease-in-out hover:translate-y-[-4px]"
                  />
                </a>
              </div>

              <div className="hidden sm:flex items-center justify-center">
                <Clock />
              </div>
            </div>

            {/* for screens greater than lg */}
            <div className="hidden lg:flex">
              <div className="flex items-center gap-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    onClick={() =>
                      //to scroll to the top when we are on other page and click any nav link, for example begin on canvas and clicking nav link me&work we scroll you to the top of me&work
                      [
                        "me&work",
                        "Gigging",
                        "Models",
                        "Canvas",
                        "Connect",
                        "DevCentral",
                      ].includes(item.name) && handleClick(item.to)
                    }
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      currentPath === item.to
                        ? "bg-black text-white px-4"
                        : "link_hover_effect",
                      "block rounded-xl py-2 text-base font-medium"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <DisclosurePanel className="w-full flex justify-center lg:hidden fixed">
        <div className="flex flex-col items-center space-y-1 w-1/2 px-2 pb-3 pt-2 mt-4 border-[2px] border-black bg-gray-50 rounded-[1rem] animate_animated animate_fadeInDown">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as={Link}
              to={item.to}
              onClick={() =>
                [
                  "me&work",
                  "Gigging",
                  "Canvas",
                  "Models",
                  "Connect",
                  "DevCentral",
                ].includes(item.name) && handleClick(item.to)
              }
              aria-current={item.current ? "page" : undefined}
              className={classNames(
                currentPath === item.to
                  ? "bg-black text-white px-4"
                  : "link_hover_effect",
                "block rounded-xl py-2 text-base font-medium"
              )}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
